import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillMail,
} from "react-icons/ai";
import { FaLinkedinIn, FaWhatsappSquare } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>Embarking on a <b className="purple">Cyber</b> Odyssey</h1>
            <p>
              I am on the hunt for exciting opportunities in
              <b className="purple"> Cyber Security</b>, especially <b className="purple">Red Team Engineering</b> and
              <b className="purple"> Security Operations</b>.
              My passion for this field is not just of a professional interest,
              but a personal mission.
              I’ve dedicated countless hours to honing my skills and understanding the intricacies of
              <b className="purple"> cybersecurity</b>,
              which is evident in the innovative projects I’ve undertaken in my own time.
              This level of commitment sets me apart.
              <br /><br />
              Let’s start with an <b className="purple">offensive</b> project:
              You know the <b className="purple"> Two-Factor/ Multi-Factor </b>Authentication security login, that has become a part of our daily routine,
              like when we access our Email/Banking/Social media or other favorite apps?
              Using a complex method, I managed to <b className="purple">bypass the
              Multi-Factor Authentication - MFA</b>,
              This is the default login user verification method used by almost all of the Tech/ Security giants in the industry.
              I even made a <b className="purple">video</b> of how I did it,
              and you can find it in the projects section of this site.<br></br>
              Almost everyone today uses MFA to keep our <b className="purple">personal / critical data</b> safe.
              Even today, tech giants like Google, Microsoft, Apple, Facebook and <b className="purple">everyone uses MFA</b> as the most secure way for the average user to log into a system.
              This was a real <b className="purple">eye-opening</b> project for me as that made me realize even the most sophisticated, well-recognized security systems have flaws/ security gaps, a threat actor could exploit.
              Realizing the depth of this security flaw made me <b className="purple">fall in love</b> with this industry <b className="purple">all over again.</b>
              <br /><br />
              Now let’s talk about my <b className="purple">defensive</b> contributions:
              Remember that little <b className="purple">padlock</b> icon in your web browser that says “<b className="purple">HTTPS</b>”?
              We all thought it was an impenetrable fortress, but ever since even bad guys could easily obtain a SSL cert easily nowadays that enables them to <b className="purple">hide behind encrypted traffic to deliver malware</b>/ conduct harmful operations,
              It has become very hard and costly to <b className="purple">detect and deter these threats.</b>
              But with a <b className="purple">little bit of brains</b> and a dash of <b className="purple">Machine Learning</b>,
              I figured out an easy way to spot the bad guys/threats hiding behind encrypted traffic.
              More details on both these projects and more, can be found at the projects section of this website.
              <br /><br />
              So, that’s me in a nutshell - a guy who loves tackling the challenges of the <b className="purple">cybersecurity</b> world.
              If you’re into <b className="purple">resilience</b>, <b className="purple">curiosity</b>,
              and pushing the boundaries of what’s possible, we’re going to get along just fine. 😎
            </p>
          </Col>
        </Row>
        <Row className="myAvtar">
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Connect With Me</h1>
            <p>
              Let's get in touch! <span className="purple">Reach out</span> to
              me.
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/VSM97"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  title="Visit my GitHub profile"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/vsmplus7"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  title="Visit my Twitter profile"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/vsm97/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  title="Visit my LinkedIn profile"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://instagram.com/vsm.97"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  title="Visit my Instagram profile"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:contact@vsm97.cf"
                  className="icon-colour home-social-icons"
                  title="Send me an Email"
                >
                  <AiFillMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://wa.me/+94777950732"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                  title="WhatsApp me"
                >
                  <FaWhatsappSquare />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
