// Blogs.js
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogCard from "./BlogCard";
import Particle from "../Particle";
import sanityClient from "../../client";

function Blogs() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      const data = await sanityClient.fetch(
        `*[_type == "post"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          excerpt,
          "name": author->name,
          "authorImage": author->image.asset->{
            _id,
            url
          },
          _createdAt
        }`
      );
      setPosts(data);
    }

    fetchPosts();
  }, []);

  return (
    <Container fluid className="blogs-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Blogs </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few <b className="purple">blogs</b> I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {posts.map((post) => (
            <Col md={4} className="blog-card">
              <BlogCard
                imgPath={post.mainImage.asset.url}
                title={post.title}
                excerpt={post.excerpt}
                name={post.name}
                authorImage={post.authorImage.url}
                publishedAt={post._createdAt}
                slug={post.slug.current}
                link={post.slug.current}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Blogs;
