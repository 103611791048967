import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Particle />
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <div style={{ paddingBottom: 15 }}>
                <h1 className="heading">
                  Welcome to the Pride{" "}
                  <span className="lion-wave" role="img" aria-labelledby="wave">
                    🦁
                  </span>
                </h1>

                <h1 className="heading-name">
                  Redefine conversations<br></br>
                  <strong className="main-name"> LionAi</strong>
                  <Type />
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
