// BlogCard.js
import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

function BlogCard(props) {
  return (
    <Card className="blog-card-view">
      <Card.Title style={{ marginTop: '15px'}}>{props.title}</Card.Title>
      <Card.Img variant="top" src={props.imgPath} alt="card-img" style={{ height: '300px', objectFit: 'cover' }} />
      <Card.Body>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.excerpt}
        </Card.Text>
        <div>
          <p><strong>published:</strong> {new Date(props.publishedAt).toDateString()}</p>
          <p><strong>author:</strong> {props.name}</p>
          <img src={props.authorImage} alt="author-img" style={{ height: '50px', justifyContent:"left" }} />
        </div>
        <Link to={`/blog/${props.slug}`} className="btn btn-primary">
          Read More
        </Link>
      </Card.Body>
    </Card>
  );
}

export default BlogCard;
