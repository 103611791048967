import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogCard from "./BlogCard";
import Particle from "../Particle";
import mfa from "../../Assets/Projects/mfa.png";

function SanityStudio() {
  return (
    <Container fluid className="blogs-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Edit <strong className="purple">Blogs </strong>
        </h1>
        <p style={{ color: "white" }}>
          You will be redirected to the <strong className="purple">Sanity Studio.</strong>
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="blog-card">
            <a href="https://vsm97.sanity.studio/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}}>
              <BlogCard
                imgPath={mfa}
                isBlog={true}
                title="Sanity Studio"
                description={<div style={{textAlign: 'center'}}>Access the admin panel here</div>}
                ghLink="https://vsm97.sanity.studio/"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default SanityStudio;
