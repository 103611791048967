import React from "react";
import { Card } from "react-bootstrap";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            🙏 <strong className="purple"> ආයුබෝවන්</strong> (āyubōwan), I am{" "}
            <span className="purple">Vihanga S. Malalaweera</span>, and I hail
            from the picturesque island of{" "}
            <span className="purple">Ceylon.</span> As a recent{" "}
            <span className="purple">Cyber Security graduate</span> from{" "}
            <span className="purple">Deakin University</span>, my journey has
            been a fusion of <span className="purple">dedication</span>,
            <span className="purple"> belief</span>, and an{" "}
            <span className="purple">unbridled passion</span> for the digital
            realm.
          </p>
          <p style={{ textAlign: "justify" }}>
            To showcase my dedication and unwavering belief in{" "}
            <span className="purple">emerging technologies</span>, I can recount
            a <span className="purple">defining moment in my tech journey</span>
            . At the end of 2015, not long after acquiring my first personal
            computer, I completed my{" "}
            <span className="purple">first blockchain transaction</span>. This
            experience was a testament to my belief in the transformative power
            of technology and how it can reshape the way we interact with the
            digital world.
          </p>
          <p style={{ textAlign: "justify" }}>
            Just over a year after purchasing that first PC, I delved deep into{" "}
            <span className="purple">networking</span>, explored the concepts of{" "}
            <span className="purple">staying anonymous online</span>, and became
            a fervent admirer of individuals like{" "}
            <span className="purple">Edward Snowden</span> and realised the{" "}
            <span className="purple">importance of open-source projects</span>{" "}
            like the <span className="purple">Tor Project</span> {" "}and the overall
            beauty of <span className="purple">open source concept</span>{" "}
            including the {" "}
            <span className="purple">Unix Philosophy</span> in
            general. During this relatively short span of time, my conversations
            revolved around the intricacies of{" "}
            <span className="purple">onion routing</span>, the importance of{" "}
            <span className="purple">anonymity for online safety</span>, and the{" "}
            <span className="purple">
              exploration of the hidden corners of the world-wide-web
            </span>
            . I naturally ventured into the basics of networking, security, and
            cryptography concepts by then.
          </p>
          <p style={{ textAlign: "justify" }}>
            It was during this period that I developed a fascination for the{" "}
            <span className="purple">Blockchain technology</span> and the{" "}
            <span className="purple">Satoshi's vision</span>. It led me towards{" "}
            acquiring my first <span className="purple">Bitcoin</span> ($BTC)
            <span className="purple"> in 2015</span>. This, officialy marked the{" "}
            <span className="purple">beginning of my journey</span> into the{" "}
            <span className="purple">security sector</span>, igniting{" "}
            <span className="purple">a spark that still burns brightly</span>.
            Even today, I maintain a keen interest in{" "}
            <span className="purple">swiftly adapting</span> to and mastering
            new emerging technologies. This ability has enabled me to{" "}
            <span className="purple">
              learn new programming languages rapidly
            </span>{" "}
            and embrace the constant evolution of the tech industry.
          </p>
          <p style={{ textAlign: "justify" }}>
            As evidence of my <span className="purple">adaptability</span> and{" "}
            <span className="purple">rapid learning</span>, I can point to my{" "}
            <span className="purple">creation of an AI-Chatbot</span>{" "}
            application in <span className="purple">March 2023</span>. Using
            Python, Flask, and a{" "}
            <span className="purple">custom-trained GPT-3.5 Turbo model</span>,{" "}
            I developed an{" "}<span className="purple">AI-Chatbot</span> that acted as a{" "}
            <span className="purple">sales assistant</span> for a local SME
            business website. This endeavor coincided with the rise of
            GPT/OpenAI in the headlines. <span className="purple"></span>
            Embracing new technologies early, much like I did with{" "}
            <span className="purple">Blockchain</span> and the{" "}
            <span className="purple">Tor Project</span>, has been{" "}
            <span className="purple">
              instrumental in my ability to quickly grasp and adapt to new
              industry trends
            </span>
            , even when most were just beginning to explore the capabilities of{" "}
            <span className="purple">ChatGPT</span>.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span className="purple">In conclusion</span>, my journey into the world of technology and
            cybersecurity has been marked by <span className="purple">relentless passion</span>, early
            <span className="purple">adoption of emerging technologies</span>, and a <span className="purple">dedication to continuous
            learning</span>. These experiences have shaped me into an <span className="purple">adaptable,
            forward-thinking professional</span> who is always <span className="purple">ready to embrace the
            future</span> of the tech industry.
          </p>
          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive for <span className="purple">excellence</span> in every
            aspect of Cyber Security!"
          </p>
          <footer className="blockquote-footer">Vihanga S. Malalaweera</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
