import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiWindows11,
  SiKalilinux,
  SiAndroid,
  SiProtonmail,
  SiSamsung,
  SiOneplus,
  SiTorproject,
  SiVmware,
  SiBinance,
  SiNvidia,
  SiTrello,
  SiWireshark,
  SiOpenai,
  SiAnaconda,
  SiBitcoin,
  SiEthereum,
  SiPytorch,
  SiTensorflow,
  SiNginx,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons" techname="Linux">
        <SiKalilinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Android">
        <SiAndroid />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Samsung">
        <SiSamsung />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Oneplus">
        <SiOneplus />
      </Col>


      <Col xs={4} md={2} className="tech-icons" techname="Openai">
        <SiOpenai />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="VS Code">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Nvidia">
        <SiNvidia />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Anaconda">
        <SiAnaconda />
      </Col>
      
      
      <Col xs={4} md={2} className="tech-icons" techname="Bitcoin">
        <SiBitcoin />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Torproject">
        <SiTorproject />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Ethereum">
        <SiEthereum />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Binance">
        <SiBinance />
      </Col>




      <Col xs={4} md={2} className="tech-icons" techname="Protonmail">
        <SiProtonmail />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Trello">
        <SiTrello />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Wireshark">
        <SiWireshark />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Vmware">
        <SiVmware />
      </Col>
      
      
      <Col xs={4} md={2} className="tech-icons" techname="Pytorch">
        <SiPytorch />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Tensorflow">
        <SiTensorflow />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Nginx">
        <SiNginx />
      </Col>
      <Col xs={4} md={2} className="tech-icons" techname="Windows">
        <SiWindows11 />
      </Col>
    </Row>
  );
}

export default Toolstack;
// SiKdenlive
// SiAnaconda

// SiApache
// SiBitcoin
// 
// SiFacebook
// SiEthereum
// SiInstagram
// SiIndeed
// SiLinux
// SiMicrosoftedge
// SiMozilla
// SiMysql
// SiNginx
// SiNgrok
// SiNumpy
// SiNvidia
// 
// SiOpenai
// SiOpenvpn
// SiOwasp
// SiOxygen
// SiPandas

// SiProtonvpn
// SiPycharm
// SiPython
// SiPytorch
// 
// SiScikitlearn
// SiScipy
// SiSelenium
// SiSoundcloud
// SiTensorflow
// SiTether
// SiThunderbird
// 
// 
// SiTrello
// 
// SiWireshark

// <Col xs={4} md={2} className="tech-icons" techname="Vmware">
// <SiVmware />
// </Col>



// <Col xs={4} md={2} className="tech-icons" techname="Vmware">
// <SiVmware />
// </Col>
// <Col xs={4} md={2} className="tech-icons" techname="Vmware">
// <SiVmware />
// </Col>
// <Col xs={4} md={2} className="tech-icons" techname="Vmware">
// <SiVmware />
// </Col>
// <Col xs={4} md={2} className="tech-icons" techname="Vmware">
// <SiVmware />
// </Col>

