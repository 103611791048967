import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient, { urlFor } from "../../client";
import BlockContent from "@sanity/block-content-to-react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { LikeButton } from "@lyket/react";

function BlogPost() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    async function fetchBlogPost() {
      try {
        const post = await sanityClient.fetch(
          `*[slug.current == $slug]{
            title,
            mainImage{
              asset->{
                _id,
                url
              }
            },
            body,
            "name": author->name,
            "authorImage": author->image.asset->{
              _id,
              url
            },
            "published":_createdAt,
          }`,
          { slug }
        );

        if (post && post.length > 0) {
          setPostData(post[0]);
        } else {
          // Handle the case where the blog post is not found
          setPostData(null);
        }
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    }

    fetchBlogPost();
  }, [slug]);

  if (!postData) {
    return (
      <div>
        <p>Blog post not found.</p>
      </div>
    );
  }

  return (
    <Container fluid className="blog-post-container">
      <Particle />
      <div className="blog-post-card-view">
        <Row style={{ justifyContent: "center"}}>
          <Col md={4} className="blog-post-card">
            <div className="blog-post-content">
              <h1>{postData.title}</h1>
              <img
                src={urlFor(postData.mainImage).width(300).url()}
                alt="Blog Post"
                className="small-image"
              />
              <div className="blog-post-data">
                <p><strong>Published:</strong>{" "}{new Date(postData.published).toDateString()}</p>
                <p><strong>Author:</strong> {postData.name}</p>
                <BlockContent
                  blocks={postData.body}
                  projectId="1dmsbh4j"
                  dataset="production"
                />
                <LikeButton
                  id={slug}
                  namespace="posts"
                  templates="twitter"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default BlogPost;
