import sanityClient from "@sanity/client";
import imageUrlBuilder from '@sanity/image-url';

const client = sanityClient({
  projectId: "1dmsbh4j",
  dataset: "production",
  useCdn: true,
});

export default client;

export const urlFor = (source) => {
  return imageUrlBuilder(client).image(source)
}
