import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Cyber Security Graduate",
          "Open Source Contributor",
          "Coding Enthusiast",
          "AI and ML Evangelist",
          "Blockchain Advocate",
          "Futurist",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 1,
      }}
    />
  );
}

export default Type;