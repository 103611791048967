import React from "react";
import Tilt from "react-parallax-tilt";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/avatar.png";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
      <Row>
          <Col md={8} className="quote-card-view">
            <h1 style={{ fontSize: '2.6em' }}>
              Know Who <strong className="purple">I am</strong>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="myAvtar">
              <Tilt>
                <img src={laptopImg} alt="about" className="img-fluid" />
              </Tilt>
            </Col>
        </Row>
        <Aboutcard />
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <Github />
      </Container>
    </Container>
  );
}

export default About;
